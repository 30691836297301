<template>
  <div>
    <app-data-grid-line-empty
      v-if="loading"
      :quantity="emptyLineQuantity"
    />

    <template
      v-if="!loading && lines.length > 0"
    >
      <app-data-grid-line
        v-for="(line, i) in lines"
        :key="i"
        :line="line"
        :show-border="i !== (lines.length - 1)"
      />
    </template>

    <app-data-grid-no-records
      v-if="!loading && lines.length === 0"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue'

import AppDataGridLine from './AppDataGridLine.vue'
import AppDataGridLineEmpty from './AppDataGridLineEmpty.vue'
import AppDataGridNoRecords from './AppDataGridNoRecords.vue'

const props = defineProps({
  // Data (lines)
  data: {
    type: Array,
    default: () => ([]),
  },
  // Is it loading?
  loading: {
    type: Boolean,
    default: true,
  },
  // quantity of empty line when loading
  emptyLineQuantity: {
    type: Number,
    default: 5,
  },
})

const lines = computed(() => (
  props.data.map((obj) => obj)
))
</script>
