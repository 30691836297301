<template>
  <div
    class="flex items-center cursor-pointer"
    @click="router.push({ name: 'show-users', params: { id: resource.id } })"
  >
    <div class="mr-3">
      <app-rounded-picture
        :picture-url="avatarUrl"
        picture-size="18"
        icon-name="user"
        icon-size="xs"
      />
    </div>

    <span>
      {{ displayName }}
    </span>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useRouter } from 'vue-router'

import AppRoundedPicture from '@shared/components/ui/AppRoundedPicture.vue'
import useResource from '@shared/hooks/resources/resource'
import useUser from '@shared/hooks/resources/user'

const props = defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
})

const router = useRouter()

const { getImageUrlFromResource } = useResource()

const avatarUrl = computed(() => (
  getImageUrlFromResource(props.resource, 'xs')
))

const { displayName } = useUser(props)
</script>
