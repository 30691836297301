<template>
  <teleport-wrapper
    v-if="claim && !loading"
    to="#breadcrumbs"
  >
    <app-breadcrumb
      :breadcrumbs="breadcrumbs"
    />
  </teleport-wrapper>

  <claim-show
    v-if="claim && !loading"
    :resource="claim"
    :loading="loading"
  />
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'

import { fetchClaim as fetchApiClaim } from '@shared/http/api'
import breadcrumbClaim from '@extranet/breadcrumbs/claim'
import AppBreadcrumb from '@extranet/components/ui/AppBreadcrumb.vue'
import ClaimShow from '@extranet/components/resources/claim/ClaimShow.vue'

const route = useRoute()

// ---------- CLAIM ----------

const claim = ref({})
const loading = ref(true)

function fetchClaim() {
  loading.value = true
  const { id } = route.params

  fetchApiClaim(id)
    .then((response) => {
      claim.value = response.data.data
    })
    .finally(() => {
      loading.value = false
    })
}

// ---------- BREADCRUMBS ----------

const { show } = breadcrumbClaim()

const breadcrumbs = computed(() => (
  show({
    id: claim.value?.id,
    label: claim.value?.attributes?.id,
  })
))

// ---------- LIFECYCLES ----------

onMounted(() => {
  fetchClaim()
})
</script>
