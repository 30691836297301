<template>
  <div>
    <div class="flex justify-end items-start md:items-center flex-col md:flex-row">
      <app-button
        v-if="resource.authorizations.update"
        feature="edit"
        class="my-1 md:mx-1"
        @click="router.push({
          name: 'edit-claims',
          params: { id: resource.id },
        })"
      />
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'

import AppButton from '@shared/components/ui/AppButton.vue'

defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Display texts
  showLabels: {
    type: Boolean,
    default: false,
  },
})

const router = useRouter()
</script>
