<template>
  <div>
    <router-link
      v-slot="{ navigate }"
      :to="{ name: 'show-points-of-interest', params: { id: resource.id } }"
      custom
    >
      <div class="flex relative">
        <!-- Image -->
        <div
          class="w-20 h-20 bg-cover bg-center mr-3 rounded-md"
          :style="`background-image: url(${getMainImageUrl('xs')});`"
          @click="withLink ? navigate() : null"
        />

        <!-- Details -->
        <div
          :class="`w-9/12 flex-1-min-w-0 ${withLink ? 'cursor-pointer' : ''}`"
          @click="withLink ? navigate() : null"
        >
          <div
            class="flex items-center justify-between"
          >
            <!-- Title -->
            <span class="font-bold text-sm truncate">
              {{ l10nTitle }}
            </span>

            <span
              v-if="resource.attributes.certified"
            >
              <app-icon
                library="coolicon"
                name="circle_check_outline"
              />
            </span>
          </div>

          <div class="text-gray-500 text-sm">
            <div
              class="flex items-center"
            >
              <!-- Category -->
              <span
                v-if="getAttributeTranslation(resource.relationships.category?.attributes?.label)"
                class="truncate"
              >
                <app-icon
                  library="coolicon"
                  name="tag-outline"
                  size="text-base"
                />
                {{ getAttributeTranslation(resource.relationships.category.attributes.label) }}
              </span>
            </div>

            <!-- Location -->
            <div
              v-if="approximateLocation(resource.relationships.address)"
              class="flex items-center"
            >
              <app-icon
                library="coolicon"
                name="location_outline"
                class="-ml-1 mr-0.5"
              />
              <span class="truncate">
                {{ approximateLocation(resource.relationships.address) }}
              </span>
            </div>

            <!-- Distance -->
            <div
              v-if="distanceToDisplay"
              class="mt-0.5"
            >
              <app-icon
                library="local"
                name="walk"
                size="w-2.5"
                :classes="['mr-1', 'ml-0.5']"
              />
              {{ distanceToDisplay }}
            </div>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script setup>
import usePointOfInterest from '@shared/hooks/resources/pointOfInterest'
import useLocale from '@shared/hooks/locale'
import useGeoloc from '@shared/hooks/geoloc'
import AppIcon from '@shared/components/ui/AppIcon.vue'

const props = defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Hide actions
  hideActions: {
    type: Boolean,
    default: false,
  },
  // Add a link to resource show page or not
  withLink: {
    type: Boolean,
    default: true,
  },
})

const { getAttributeTranslation } = useLocale()
const { approximateLocation } = useGeoloc()
const {
  l10nTitle,
  getMainImageUrl,
  distanceToDisplay,
} = usePointOfInterest(props)
</script>
