/**
 * Hook used to manage breadcrumbs
 */

export default function useBreadcrumb() {
  /**
   * Force link property to set to true
   * for all array items
   *
   * @param breadcrumbs
   * @returns {*}
   */
  function forceLink(breadcrumbs) {
    return breadcrumbs.map((breadcrumb) => (
      { ...breadcrumb, link: true }
    ))
  }

  return {
    forceLink,
  }
}
